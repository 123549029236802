<template>
  <div class="content-body">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12">
          <h2>Free Test NFT Minting</h2>
          <p>
            Mint Pixelguy:
            <button @click="mint(20, 'pgc')" class="btn btn-primary">
              Mint 20</button
            >&nbsp;
            <button @click="mint(25, 'pgc')" class="btn btn-primary">
              Mint 25
            </button>
          </p>
          <p>
            Mint Shorai:
            <button @click="mint(15, 'shorai')" class="btn btn-primary">
              Mint 15</button
            >&nbsp;
            <button @click="mintShorai(20, 'shorai')" class="btn btn-primary">
              Mint 20
            </button>
          </p>
          <p>
            Mint Kensho:
            <button @click="mint(15, 'kensho')" class="btn btn-primary">
              Mint 15</button
            >&nbsp;
            <button @click="mint(20, 'kensho')" class="btn btn-primary">
              Mint 20
            </button>
          </p>
          <!-- <p>
            Mint Kensho Exclusive:
            <button @click="mint(15, 'kenshoX')" class="btn btn-primary">
              Mint 15</button
            >&nbsp;
            <button @click="mint(20, 'kenshoX')" class="btn btn-primary">
              Mint 20
            </button>
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import SimpleNft from "../../artifacts/contracts/SimpleNFT.sol/SimpleNFT.json";

export default {
  name: "App",
  components: {},
  data() {
    return {
      provider: null,
    };
  },
  methods: {
    mint: async function (amount, _collection) {
      let c = new ethers.Contract(
        window.address[_collection],
        SimpleNft.abi,
        this.provider.getSigner()
      );
      let tx = await c.mint(amount, {
        gasLimit: 9900000,
      });
      await tx.wait();
      window.location.reload();
    },
  },
  mounted: function () {
    this.provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    this.provider.send("eth_requestAccounts", []).then(() => {
      this.signer = this.provider.getSigner();
    });
  },
};
</script>